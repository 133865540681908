/* General Styling */
body {
  margin: 0;
  padding: 0;
  background-color: #000;
  color: #fff;
  font-family: Arial, sans-serif;
}

.admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  position: relative;
}

.logo {
  max-width: 300px;
  height: auto;
  margin-bottom: 20px;
}

.title {
  font-size: 3rem;
  color: #ff0000;
  margin: 10px 0;
}

.subtitle {
  font-size: 2rem;
  color: #ff0000;
  margin: 0 10px 0 0;
}

.slogan {
  font-size: 1.2rem;
  color: #fff;
  margin: 10px 0;
}

.tracker-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

/* Button Styling */
.back-button, .admin-button, .generate-key-button, .update-button, .reset-button, .remove-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.3s;
}

.back-button:hover,
.admin-button:hover,
.generate-key-button:hover,
.update-button:hover,
.reset-button:hover,
.remove-button:hover {
  background-color: #0056b3;
}

/* User Table Styling */
.admin-section {
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
}

.user-table th,
.user-table td {
  border: 1px solid #fff;
  padding: 10px;
  text-align: center;
}

.user-table th {
  background-color: #333;
}

.user-table td {
  background-color: #222;
}

.status {
  padding: 5px 10px;
  border-radius: 5px;
}

.status.active {
  background-color: #28a745;
  color: #fff;
}

.status.expired {
  background-color: #dc3545;
  color: #fff;
}

/* License Key Management */
.license-section {
  width: 100%;
  max-width: 800px;
  margin-top: 40px;
  text-align: left;
}

.generate-key-button {
  background-color: #28a745;
  margin-bottom: 10px;
}

.generate-key-button:hover {
  background-color: #218838;
}

.license-key-list {
  list-style-type: none;
  padding: 0;
  margin-top: 10px;
}

.license-key-list li {
  background-color: #f8f9fa;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete-key-button {
  background-color: #dc3545;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-key-button:hover {
  background-color: #c82333;
}

/* Responsive Styling */
@media only screen and (max-width: 600px) {
  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }

  .user-table th,
  .user-table td {
    padding: 5px;
    font-size: 0.9rem;
  }

  .license-section {
    max-width: 100%;
  }
}
