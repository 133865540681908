.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #000;
  font-family: Arial, sans-serif;
}

.content-wrapper {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

.logo {
  width: 150px;
  height: auto;
  margin-bottom: 20px;
}

.title {
  font-size: 42px;
  color: #ff0000;
  margin-bottom: 10px;
}

.slogan {
  font-size: 20px;
  color: #ccc;
  margin-bottom: 30px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.license-input {
  padding: 12px;
  font-size: 16px;
  border: 2px solid #444;
  border-radius: 5px;
  margin-bottom: 15px;
  width: 300px;
  background-color: #fff;
}

.submit-button {
  padding: 12px 24px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.submit-button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}
