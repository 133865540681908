/* ShieldInputPage.css */ 
body {
  margin: 0;
  padding: 0;
  background-color: #000;
  color: #fff;
  font-family: Arial, sans-serif;
}

.shield-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px;
  text-align: center;
  position: relative;
}

.tracker-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 2rem;
  color: #ff0000;
  margin: 0 10px 0 0;
}

.admin-button {
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
  margin-left: 10px; /* Added margin to separate from Shield Tracker */
}

.admin-button:hover {
  background-color: #555;
}

.logo {
  max-width: 300px;
  height: auto;
  margin-bottom: 20px;
}

.title {
  font-size: 3rem;
  color: #ff0000;
  margin: 10px 0;
}

.slogan {
  font-size: 1.2rem;
  color: #fff;
  margin: 10px 0;
}

.countdown {
  margin: 20px 0;
}

.timer {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 10px 0;
}

.shield-form, .notification-settings {
  margin: 20px 0;
  width: 100%;
  max-width: 400px;
}

.input-label {
  display: flex;
  flex-direction: column;
  margin: 10px 0;
  font-size: 1rem;
}

.input-field {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #fff;
  background-color: #333;
  color: #fff;
  margin-top: 5px;
}

.update-button {
  padding: 12px 25px;
  font-size: 1.2rem;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.update-button:hover {
  background-color: #218838;
}

.instruction-text {
  font-size: 0.9rem;
  color: #ccc;
  margin-top: 10px;
}

.notify-button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 10px;
}

.notify-button:hover {
  background-color: #0056b3;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin: 5px 0;
  font-size: 1rem;
}

.checkbox {
  margin-right: 10px;
}

/* Responsive Styling */
@media only screen and (max-width: 600px) {
  .title {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }

  .timer {
    font-size: 2rem;
  }

  .input-field {
    width: 100%;
  }

  .update-button, .notify-button {
    width: 100%;
  }
}
