/* index.css */
body {
  margin: 0;
  padding: 0;
  background-color: #000; /* Black background */
  color: #fff; /* White text color for contrast */
  font-family: Arial, sans-serif; /* Optional: Add a default font */
}

#root {
  min-height: 100vh; /* Ensure the root element takes the full height */
}
